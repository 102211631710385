<template>
   <form @submit.prevent="submitForm" class="order-form form" id="order-form" action="#" novalidate>
      <app-new-user
         :is-displayed-error="isDisplayedError"
         :is-form-submission="isFormSubmission"
         @result-checking="saveResultChecking"
         @form-reset="resetForm"
         class="order-form__wrap"
      />

      <app-delivery class="order-form__wrap" />
      <app-mail class="order-form__wrap" />
      <app-payment class="order-form__wrap" />
   </form>
</template>

<script>
   import AppNewUser from './modules/AppNewUser';
   import AppDelivery from './modules/AppDelivery';
   import AppMail from './modules/AppMail';
   import AppPayment from './modules/AppPayment';
   import { mapActions } from 'vuex';

   export default {
      name: 'OrderForm',

      components: {
         AppNewUser,
         AppDelivery,
         AppMail,
         AppPayment
      },

      inject: ['openModal', 'backToTopOfPage'],

      data() {
         return {
            isFormSubmission: false,
            isDisplayedError: false,
            fieldsValidationResults: [],

            modalSettings: {
               name: 'ModalNotification',
               iconName: 'ready',
               message: 'Спасибо, Ваш заказ принят!',
               isActive: true
            }
         };
      },

      methods: {
         ...mapActions('cart', ['emptyTrash']),

         saveResultChecking(value) {
            const field = this.fieldsValidationResults.find(
               (item) => item.name === value.name && item.type === value.type
            );

            if (field) {
               field.isValid = value.isValid;
            } else {
               this.fieldsValidationResults.push(value);
            }
         },

         resetForm(value) {
            this.isFormSubmission = value;
         },

         submitForm() {
            if (this.isValidFieldValue) {
               this.isFormSubmission = true;
               this.isDisplayedError = false;

               this.emptyTrash();

               this.openModal(this.modalSettings);
               this.$router.replace({ name: 'Home' });
            } else {
               this.isDisplayedError = true;
               this.backToTopOfPage('smooth');
            }
         }
      },

      computed: {
         isValidFieldValue() {
            return (
               this.fieldsValidationResults.length &&
               this.fieldsValidationResults.every((item) => item.isValid)
            );
         }
      }
   };
</script>
