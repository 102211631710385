<template>
   <div class="order-form__wrap--payment">
      <h3 class="order-form__title order-form__title--payment">Способы оплаты:</h3>

      <div class="order-form__payment">
         <label class="custom-label">
            <input
               class="custom-input"
               type="radio"
               name="payment"
               data-custom-radio="true"
               checked
            />
            <span class="custom-text">Наличными при получении товара</span>
         </label>

         <label class="custom-label">
            <input class="custom-input" type="radio" name="payment" data-custom-radio="true" />
            <span class="custom-text">Банковской картой</span>
         </label>
      </div>
   </div>
</template>

<script>
   export default {
      name: 'AppPayment'
   };
</script>
