<template>
   <section class="purchases">
      <div class="container">
         <h3 class="purchases__title">Ваши покупки: {{ productsCount }} товар(a/ов)</h3>

         <div class="purchases__wrap">
            <product-card v-for="product in products" :key="product.id" :product="product" />

            <p v-if="!productsCount" class="purchases__empty-cart">Пустая корзина</p>
         </div>

         <footer class="purchases__footer">
            <p class="purchases__total-amount">
               Итого, к оплате: <span>{{ totalAmount }}&nbsp;руб.</span>
            </p>

            <button :disabled="!productsCount" class="purchases__btn btn" form="order-form">
               Оформить заказ
            </button>
         </footer>
      </div>
   </section>
</template>

<script>
   import ProductCard from './ProductCard';
   import { mapState, mapGetters } from 'vuex';

   export default {
      name: 'AppPurchases',

      components: {
         ProductCard
      },

      computed: {
         ...mapState('cart', ['products']),
         ...mapGetters('cart', ['productsCount']),

         totalAmount() {
            return this.products.reduce((sum, product) => product.price * product.count + sum, 0);
         }
      }
   };
</script>
