<template>
   <main class="main">
      <app-breadcrumbs last-element="Оформление заказа" />

      <section class="main__order order">
         <div class="container">
            <h2 class="order__section-title section-title">Оформление заказа</h2>

            <order-form />
         </div>
      </section>

      <app-purchases class="main__purchases" />

      <app-subscription />
   </main>
</template>

<script>
   import AppBreadcrumbs from '@/components/templates/AppBreadcrumbs';
   import OrderForm from './modules/Form/OrderForm';
   import AppPurchases from './modules/Purchases/AppPurchases';
   import AppSubscription from '@/components/templates/AppSubscription';
   import { mapGetters } from 'vuex';

   export default {
      name: 'OrderPage',

      components: {
         AppBreadcrumbs,
         OrderForm,
         AppPurchases,
         AppSubscription
      },

      inject: ['backToTopOfPage'],

      mounted() {
         if (!this.productsCount) {
            this.$router.replace({ name: 'Home' });
         }

         this.backToTopOfPage();
      },

      computed: {
         ...mapGetters('cart', ['productsCount'])
      }
   };
</script>
