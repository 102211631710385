<template>
   <div class="order-form__wrap--mail">
      <h3 class="order-form__title order-form__title--mail">Почта России:</h3>

      <div class="order-form__inner">
         <button
            :data-param="dropdownList.type"
            @click="showSelectedParam(dropdownList)"
            class="order-form__dropdown-header dropdown-header btn"
            type="button"
         >
            {{ dropdownList.selectedProp.name }}
         </button>

         <ul
            :class="{ 'displayed-filter': selectedParam }"
            class="order-form__dropdown-body dropdown-body"
         >
            <li v-for="prop in dropdownList.props" :key="prop.name" class="dropdown-body__item">
               <button
                  @click="setSelectedProp(dropdownList, prop)"
                  class="dropdown-body__btn btn"
                  type="button"
               >
                  {{ prop.name }}
               </button>
            </li>
         </ul>
      </div>
   </div>
</template>

<script>
   export default {
      name: 'AppMail',

      data() {
         return {
            dropdownList: {
               type: 'mail',
               props: [
                  { name: 'Отделение №1' },
                  { name: 'Отделение №2' },
                  { name: 'Отделение №3' }
               ],
               selectedProp: {}
            },

            selectedParam: ''
         };
      },

      mounted() {
         this.setSelectedProp(this.dropdownList, this.dropdownList.props[0]);

         document.addEventListener('click', this.closeDropdownList);
      },

      beforeUnmount() {
         document.removeEventListener('click', this.closeDropdownList);
      },

      methods: {
         showSelectedParam(param) {
            this.selectedParam = this.selectedParam !== param.type ? param.type : '';
         },

         closeDropdownList(e) {
            if (!e.target.dataset.param) {
               this.selectedParam = '';
            }
         },

         setSelectedProp(param, prop) {
            if (Object.keys(param.selectedProp).length) {
               param.props.push(param.selectedProp);
            }

            param.selectedProp = prop;
            param.props.splice(param.props.indexOf(prop), 1);
            param.props.sort((a, b) => (a.name < b.name ? -1 : 1));
         }
      }
   };
</script>
