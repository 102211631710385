<template>
   <div class="order-form__wrap--customer">
      <h3 class="order-form__title order-form__title--customer">Новый покупатель</h3>

      <div class="customer">
         <app-field
            v-for="field in fields"
            :key="field.name"
            :is-form-submission="isFormSubmission"
            :is-displayed-error="isDisplayedError"
            :settings="field"
            @result-checking="transferResultChecking"
            @form-reset="transferFormReset"
         />
      </div>
   </div>
</template>

<script>
   import AppField from '@/components/templates/FormFields/AppField';

   export default {
      name: 'AppNewUser',

      components: {
         AppField
      },

      props: {
         isFormSubmission: { type: Boolean, required: true },
         isDisplayedError: { type: Boolean, required: true }
      },

      emits: {
         'result-checking': (value) => typeof value === 'object',
         'form-reset': (value) => typeof value === 'boolean'
      },

      data() {
         return {
            fields: [
               {
                  name: 'name',
                  type: 'text',
                  validationType: 'Name',
                  placeholder: 'Имя',
                  params: { minLength: 2, maxLength: 20 }
               },
               {
                  name: 'country',
                  type: 'text',
                  validationType: 'Message',
                  placeholder: 'Страна',
                  params: { minLength: 3, maxLength: null }
               },
               {
                  name: 'surname',
                  type: 'text',
                  validationType: 'Name',
                  placeholder: 'Фамилия',
                  params: { minLength: 2, maxLength: 20 }
               },
               {
                  name: 'city',
                  type: 'text',
                  validationType: 'Message',
                  placeholder: 'Город',
                  params: { minLength: 3, maxLength: null }
               },
               {
                  name: 'Email',
                  type: 'email',
                  validationType: 'Email',
                  placeholder: 'E-mail'
               },
               {
                  name: 'street',
                  type: 'text',
                  validationType: 'Message',
                  placeholder: 'Улица',
                  params: { minLength: 3, maxLength: null }
               },
               {
                  name: 'phone',
                  type: 'tel',
                  validationType: 'Phone',
                  placeholder: 'Номер телефона'
               },
               {
                  name: 'other',
                  type: 'text',
                  validationType: 'Message',
                  placeholder: 'Дополнительно'
               }
            ]
         };
      },

      methods: {
         transferResultChecking(value) {
            this.$emit('result-checking', value);
         },

         transferFormReset(value) {
            this.$emit('form-reset', value);
         }
      }
   };
</script>
