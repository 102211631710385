<template>
   <div class="order-form__wrap--delivery">
      <h3 class="order-form__title order-form__title--delivery">Методы доставки:</h3>

      <div class="order-form__delivery">
         <label v-for="item in deliveryMethods" :key="item.name" class="custom-label">
            <input
               :checked="item.checked"
               class="custom-input"
               type="radio"
               name="delivery"
               data-custom-radio="true"
            />
            <span class="custom-text">{{ item.name }}</span>
         </label>
      </div>
   </div>
</template>

<script>
   export default {
      name: 'AppDelivery',

      data() {
         return {
            deliveryMethods: [
               {
                  name: 'Почта России',
                  checked: true
               },
               { name: 'Fedex' },
               { name: 'СДЭК' },
               { name: 'SPSR Express' },
               { name: 'DHL' },
               { name: 'Самовывоз' }
            ]
         };
      }
   };
</script>
