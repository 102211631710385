<template>
   <article class="product-card">
      <div class="product-card__wrap">
         <img :src="product.image" :alt="product.imageDescription" class="product-card__img" />
      </div>

      <div class="specifications">
         <div class="specifications__inner" data-elem="name">
            <h4 class="specifications__title">Наименование:</h4>

            <router-link
               :to="{ name: 'Product', params: { id: product.id } }"
               class="specifications__link"
            >
               {{ product.name }}
            </router-link>
         </div>

         <div class="specifications__inner" data-elem="desc">
            <h4 class="specifications__title">Описание:</h4>

            <p class="specifications__text">
               {{ productDescription }}
               <span v-if="product.description.length >= maxStrLength">...</span>
            </p>
         </div>

         <div class="specifications__inner" data-elem="count">
            <h4 class="specifications__title">Кол-во:</h4>

            <div class="products-count">
               <input
                  v-model="productsCount"
                  @input="setProductsCount(product)"
                  class="products-count__input input"
                  type="text"
               />

               <div class="products-count__wrap">
                  <button
                     @click="decreaseProductsCount(product)"
                     class="products-count__btn products-count__btn--decrease btn"
                  ></button>
                  <button
                     @click="increaseProductsCount(product)"
                     class="products-count__btn products-count__btn--increase btn"
                  ></button>
               </div>
            </div>
         </div>

         <div class="specifications__inner" data-elem="price">
            <h4 class="specifications__title">Итого:</h4>

            <p class="specifications__text">{{ totalPrice }}&nbsp;руб.</p>
         </div>
      </div>

      <button @click="removeProductFromOrder(product)" class="product-card__remove btn">
         <remove-icon class="product-card__icon" />
      </button>
   </article>
</template>

<script>
   import RemoveIcon from '@/components/Icons/RemoveIcon';
   import { mapActions } from 'vuex';

   export default {
      name: 'ProductCard',

      components: {
         RemoveIcon
      },

      props: {
         product: { type: Object, required: true }
      },

      data() {
         return {
            productsCount: 0,
            maxStrLength: 85
         };
      },

      methods: {
         ...mapActions('cart', [
            'saveProductState',
            'updateProductCountState',
            'saveProductCountState'
         ]),

         removeProductFromOrder(product) {
            this.saveProductState({ product, isRemove: true });
         },

         setProductsCount(product) {
            const value = +this.productsCount.replace(/\D/g, (match, offset, string) =>
               string.length > 1 ? '' : 1
            );

            if (value >= 1) {
               this.updateProductCountState({ product, value });
               this.productsCount = value;
            } else {
               this.saveProductState({ product, isRemove: true });
            }
         },

         decreaseProductsCount(product) {
            if (product.count > 1) {
               this.saveProductCountState({ product, isIncrease: false });
            } else {
               this.saveProductState({ product, isRemove: true });
            }
         },

         increaseProductsCount(product) {
            this.saveProductCountState({ product, isIncrease: true });
         }
      },

      computed: {
         productDescription() {
            return this.product.description.slice(0, this.maxStrLength);
         },

         totalPrice() {
            return this.product.price * this.product.count;
         }
      },

      watch: {
         'product.count': {
            handler(value) {
               this.productsCount = value;
            },
            immediate: true
         }
      }
   };
</script>
